.bigLogo {
    border-width: 0px;
    background-color: #f0f0f0;
}

.bigLogo img {
    width: 20%;
    justify-self: center;
}

.collageImages {
    justify-self: center;
    width: 100%;
    display: flex;
    flex-direction: row;
}
  
@media (max-width: 728px) {
    .bigLogo img {
        width: 60%;
        justify-self: center;
    }
      
    .collageImages {
        flex-direction: column;
    }
}