.notFoundPage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.notFoundPage img {
    width: 20%;
    max-width: 300px;
    height: auto;
}

.description {
    font-size: 14px;
}

.boldLabel {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 10px;
}