.step1Container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding-top: 10px;
}

.collageOption {
    flex: 1 1 0;
    min-width: 150px;
    margin: 5px;
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc; 
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
}

.optionTitle {
    font-size: 24px;
    font-weight: bold;
    padding: 1px;
    margin: 1px;
}

.considerationsTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px
}

.consideration {
    font-size: 16px;
    padding: 1px;
    margin: 0px;
    text-align: left;
}

.optionImage {
    margin-top: 10px;
    width: 100%;
}

@media (max-width: 728px) {
    .step1Container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
        padding-top: 10px;
    }

    .collageOption {
        flex: 1 1 0;
        flex-direction: row;
        min-width: 150px;
        margin: 5px;
        padding: 10px;
        text-align: center;
        border: 1px solid #ccc; 
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease;
    }
}