.orderInfo p {
    margin-top: 2px;
    margin-bottom: 2px;
}

.flexContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
  
.label {
    flex-basis: 50%;
    text-align: right;
    margin-right: 5px;
}
  
.value {
    flex-basis: 50%;
    text-align: left;
}

input, textarea {
    flex: 2;
    padding: 5px;
    font-size: 16px;
}

input, select {
    width: 100%;
    padding: 5px;
    margin: 3px 0;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}
  
input:focus, select:focus {
    border-color: #555;
    outline: none;
}


.loadingScreenView {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}