.tipsSetup {
    display: flex;
    flex-direction: row;
}

.tipsContainer {
    flex: 1;
    margin: 0px 25px;
}

.tipsHeader {
    font-size: 26px;
    margin: 5px;
}

.tipText {
    font-size: 18px;
    margin: 8px;
    text-align: left;
}
  
@media (min-width: 768px) {
    .tipsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .tipsDropdown {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
      
    .tipsDropdown span {
        font-size: 24px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }
      
    .tipsDropdown .caret {
        font-size: 1.2em;
    }
      
    .tipsDropdownContent {
        margin-top: 5px;
    }
}
  