.mediumLogoHeader {
    font-size: calc(10px + 2vmin);
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.headerLeft {
    display: flex;
    position: relative;
    left: 0;
    width: 16%;
}

.headerCenter {
    position: relative;
    display: flex;
    justify-content: center;
    width: 84%;
    transform: translate(-10%); /*Goal: center to be at 50%: center is currently 16 + 84/2 = 58. We need to move it 8 to the left, which is 8/84 or 10 percent*/
}

  
.mediumLogo { 
    width: 90%;
    display: flex;
    cursor: pointer;
}