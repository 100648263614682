.cropperButton {
    display: inline-block;
    padding: 8px 16px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    margin: 0px 3px;
}

.cropperButton:hover {
    background-color: #0056b3;
}

.cropperButton:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}