.App {
    padding: 20px;
    background-color: #f0f0f0 ;
    min-height: 100vh;
    color: #282c34;
    text-align: center;
    display: grid;
    align-content: flex-start;
}

.rowDisplay {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.largeImage {
    width: 70%;
    margin: 0 auto;
    overflow: hidden;
}