.pageContainer {
    display: flex;
    flex-direction: row;
    gap: 50px
}

.sizeContainer {
    flex: 1;
    justify-items: center;
    flex-direction: column;
    border: 1px solid #ccc; 
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
}

.sizeText {
    padding: 0;
    margin: 10px;
    font-size: 24px;
    font-weight: 500;
}

.image {
    width: auto;
    height: 150px;
    margin-top: 10px;
}

@media (max-width: 728px) {
    .pageContainer {
        flex-direction: column;
        gap: 25px;
    }
}