.generalButton {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
}

.generalButton:hover {
    background-color: #0056b3;
}

.generalButton:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}