.buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.confirmContainer {
    justify-items: center;
    margin-top: 20px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.previewContainer {
    display: flex;
    justify-content: center;
}

.previewImage {
    width: 40%;
    height: auto;
    justify-self: center;
}

@media (max-width: 728px) {
    .previewImage {
        width: 75%;
    }
}