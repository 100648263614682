.billingSection {
    display: flex;
    flex-direction: column;
    margin: 0px;
}

.billingRow {
    display: flex;
    flex-direction: row;
    margin: 0px;
}

.leftBillingRow {
    flex: 11;
    text-align: right;
    margin-right: 5px;
}

.rightBillingRow {
    flex: 10;
    text-align: left;
}

.underline {
    margin-top: 4px;
    border-bottom: 3px solid black;
}