.outputContainer {
    display: flex;
    justify-content: center;
}

.chooseCropView {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.cropImageText {
    font-size: 22;
}

.mainImageContainer {
    width: 40%;
    justify-self: center;
}

.mainImage {
    width: 100%;
}

.textInputView {
    width: auto;
}

.dropdownContainer {
    justify-items: center;
    justify-self: center;
    width: auto;
    align-content: center;
}

.confirmContainer {
    justify-items: center;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
}

.symbolContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.symbolImage {
    width: 40%;
}

.loadingScreenView {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 728px) {
    .symbolImage {
        width: 75%;
    }
}