.alignedForm {
    flex-direction: column;
    margin: 0 auto;
    width: 90%;
    max-width: 600px;
}

.labelDiv {
    flex: 1;
    display: flex;
    margin-right: 10px;
    margin-top: 8px;
    justify-content: flex-end;
}

.formFieldContainer {
    display: flex;
}
  
.requiredAsterisk {
    color: red;
}

.labelField {
    flex: 1;
    display: flex;
    align-items: flex-end;
}

.checkboxContainer {
    flex: 1.75;
    display: flex;
    align-items: flex-end;
}