.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imageItem img {
    max-width: 100%;
    max-height: 120px;
}

.imageItem {
    display: flex;
    flex-direction: column;
    margin: 25px 50px;
    align-items: center;
}

.wrapRowContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.loadingScreenView {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 728px) {
    .imageItem img {
        max-width: 100%;
        max-height: 90px;
    }
    
    .imageItem {
        margin: 10px 20px;
    }
}