.cropperContainer {
    display: grid;
    justify-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    transition: none !important;
    animation: none !important;
}

.controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
}
  
.controls input[type="range"] {
    width: 100%;
    margin-bottom: 10px;
    z-index: 1000;
}
  
.controls button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 20px;
    cursor: pointer;
    border-radius: 5px;
    z-index: 1000;
}
  
.controls button:hover {
    background-color: #0056b3;
}

.controls button:disabled {
    background-color: #cccccc;
    cursor: not-allowed; 
}

.wrapRowContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 728px) {
    .controls {
        position: absolute;
        bottom: 90px;
        left: 30%;
        width: 70%;
        transform: translateX(-21.4%);
        background-color: rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 5px;
        z-index: 1000;
    }

    .controls input[type="range"] {
        width: 100%;
        margin-bottom: 10px;
        height: 20px;
        z-index: 1000;
    }
    
    .controls button {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 8px 20px;
        cursor: pointer;
        border-radius: 5px;
        z-index: 1000;
    }
    
    .controls button:hover {
        background-color: #0056b3;
    }

    .controls button:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }

    .slider {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 12px;
        background-color: #ddd;
        outline: none;
        transition: opacity .15s ease-in-out;
        border-radius: 5px;
    }
    
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 30px;
        height: 30px;
        background-color: #007bff;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid #ddd;
    }
    
    .slider::-moz-range-thumb {
        width: 30px;
        height: 30px;
        background-color: #007bff;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid #ddd;
    }
}