.toastContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    max-width: 50%;
    width: auto;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    box-sizing: border-box;
}

.toastContainer label {
    font-size: 16px;
}

.toastOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9998;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}

.boldButton {
    font-weight: bold;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.boldButton:hover {
    background-color: #0056b3;
}

.dimButton {
    font-weight: normal;
    padding: 10px 15px;
    background-color: #d9dde0;
    color: #5a6167;
    border: 1px solid #9da5ac;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s;
    margin-left: 5px;
}

.dimButton:hover {
    background-color: #8a9096;
    color: #474b4f;
}