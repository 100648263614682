.collageCreationTitleContainer {
    justify-content: center;
    padding: 0px;
}

.collageCreationTitle {
    font-size: 32px;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
}

.subtitleContainer {
    width: 75%;
    justify-self: center;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.collageCreationSubtitle {
    font-size: 20px;
    font-weight: 400;
    margin: 0px;
    padding-top: 5px;
    padding-bottom: 20px;
}