.dropDown {
    display: inline-block;
    padding: 10px 3px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    width: auto;
    min-width: 50px;
}