.collageImage {
    width: 100%;
    height: auto;
    transition: transform 0.5s ease-in-out;
}
  
.collageImage:hover {
    transform: scale(6);
}

.zoomSelectionContainer {
    justify-content: center;
}

.zoomSelectionContainer p {
    margin-top: 10px;
    margin-bottom: 3px;
}

.zoomSelectionContainer select {
    display: inline-block;
    width: auto;
    margin-bottom: 10px;
}

.imageZoomContainer {
    width: 40%;
    justify-self: center;
}